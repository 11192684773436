/* eslint-disable */
import React from 'react';
import './App.css';
import Minter from './projects/Minter' 
import RSS from './views/rss' 
//import Pixelfoxes from './projects/Pixelfoxes'
  

 
var url = require('url');

var getUrl = (window.location).toString();
//console.log(getUrl);
var getAddress = url.parse(getUrl, true);
var getPathname = getAddress.pathname;
//console.log(getPathname);
 
  
class App extends React.Component {
 
    render() {
         
        switch (getPathname) {
            
            
            case "/":
                return (
                    <div className="App">
                    <RSS/>
                    </div>
                );
                break;

            default:
                return (
                    <div className="App">
                        <RSS/>
                    </div>
                );
                break;
        }
    }
}

export default App;

