/* eslint-disable */
 
import { pinFileToIPFS } from "./pinata.js";
require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY_CYPHER;
const publicKey = process.env.REACT_APP_PUBLIC_KEY_CYPHER; 
const baseURL = process.env.REACT_APP_BASE_URL_CYPHER;
const uploadPath = process.env.REACT_APP_UPLOAD_PATH_CYPHER;
const pkey = process.env.REACT_APP_PINATA_KEY_CYPHER;
const psecret = process.env.REACT_APP_PINATA_SECRET_CYPHER;
const jsonPath = process.env.REACT_APP_UPLOAD_JSON_PATH_CYPHER;
const nftName = process.env.REACT_APP_NFT_NAME_CYPHER;
const nftDescription = process.env.REACT_APP_NFT_DESCRIPTION_CYPHER;
const nftFolderName = process.env.REACT_APP_NFT_FOLDERNAME_CYPHER;
const nftTotalSupply = process.env.REACT_APP_NFT_TOTAL_SUPPLY_CYPHER;
 
const FormData = require('form-data');
const contractABI = require("../contract-abi.json");


const contractABICypher = require("../contract-abi.json");
const alchemyKeyPF = process.env.REACT_APP_ALCHEMY_KEY_CYPHER;
const publicKeyPF = process.env.REACT_APP_PUBLIC_KEY_CYPHER; 
const contractMintPrice = process.env.REACT_APP_MINT_PRICE_CYPHER; 

const fs = require('fs')
const jquery = require('jquery')
 
//const contractAddress = "0x412dedc34edE1198a27130547A63B6Bfcf65B189"; //ropsten
//const contractAddress = "0x89858b18dc3e10Ce52B8d96D83d17Ec0DBc05a59"; //rinkeby --old 
//const contractAddress = "0xfee6eae3FA88f4e4e0407eE934b807c1707a7016"; //rinkeby --traveltocuans
//const contractAddress = "0x959924974b13EcfbE77dC46b55B9F86E6d816B94"; //rinkby -pf
const contractAddress =  process.env.REACT_APP_PB_CYPHER; 
const contractAddressCypher =  process.env.REACT_APP_PB_CYPHER; 

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
const web3PF = createAlchemyWeb3(alchemyKeyPF);
const Web3 = require("web3");

export const getOwnedTokens = async() => {

    var tokenInst = await new web3.eth.Contract(contractABI, contractAddress);
   
    tokenInst.methods.balanceOf(window.ethereum.selectedAddress).call().then(function (bal) {
       // console.log(bal);

       for(var i = 0; i < bal.toNumber(); i++) {
           tokenInst.tokenOfOwnerByIndex.call(window.ethereum.selectedAddress, i)
           .then((id) => { 
       
              // console.log("I own token ID:" + id.tostring())
           });       
       }   
 
    })
 

}

export const doesCypherExist = async () => {

    var tokenInst = await new web3.eth.Contract(contractABI, contractAddress);

    var balance = await tokenInst.methods.balanceOf(window.ethereum.selectedAddress).call();
     
    return balance;
 
}




function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;

    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
}


export const connectWallet = async () => {
    if (window.ethereum) {

         //const networkId = await web3.eth.net.getId();
        //const networkType = await web3.eth.net.getNetworkType();

       // console.log(networkId)
       // console.log(networkType)

    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
         // status: "👆🏽 Write a message in the text-field above.",
          status: "connected",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={'dapp://cyphernews.io'}>
              You must install an ethereum wallet in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {

        const networkId = await web3.eth.net.getId();
        const networkType = await web3.eth.net.getNetworkType();

        //console.log(networkId)
        //console.log(networkType)
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
        if (addressArray.length > 0) {

            //showm mint button here

        return {
          address: addressArray[0],
          status:"connected",
        };
      } else {
        return {
          address: "",
          status: "Connect to your ethereum wallet using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`dapp://cyphernews.io`}> 
                You must install an ethereum wallet in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

async function loadContract() {
  return new web3.eth.Contract(contractABI, contractAddress);
}

export async function loadContract_PF() {
    return new web3PF.eth.Contract(contractABICypher,  process.env.contractAddressCypher);
}


async function writeIfMissing(id){
    //check if file exists
    try {
        const file = `./sold_tokens/${id}`;
        const final_file = `./final_metadata/${id}`;
        // file missing
        if (!fs.existsSync(file) && fs.existsSync(final_file)) {
            try {
                await this.logTransfer(id);
            } catch (err) {
                console.log(err);
            }
        }
    } catch(err) {
        console.error(err)
    }
}
 



async function writeFile(id,sNFTMetaFileName, jsonString){
    //check if file exists
    try {
      fs.writeFile(sNFTMetaFileName, jsonString, err => {
        if (err) {
            console.log('Error writing file', err)
        } else {
            console.log('Successfully wrote file')

                //now mint 

            }
        })
    } catch(err) {
        console.error(err)
    }
}
async function logTransfer(id) {
    try {
        var oldPath = `./final_metadata/${id}`;
        var newPath = `./sold_tokens/${id}`;

        fs.copyFile(oldPath, newPath, function (err) {
            if (err) throw err
        })
    } catch (err) {
        console.log(err);
    }
}


var saveNote = function(note) {
     

    
};


export const getTotalContractSupply = async () => {

    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const nftContract = window.contract; 
    const sTotalSupply = await nftContract.methods.totalSupply().call()
    const nNftTotalSupply = parseInt(nftTotalSupply);
   
    return {
        TotalSupply: nNftTotalSupply 
    };
}
 
export const getNetworkStatus = async() => {
    const ethereum = await web3Instance();
    if (!ethereum) return -1;
    const chainId = await ethereum.eth.getChainId();
   //console.log(chainId);
    return chainId;
  };
  
  const getSignatureData = async(mintNumber) => {
  
    const axios = require('axios');
  
   // let res = await axios.post('https://minting.oxfordcircle.io/api/getWhitelistOxc', {
    //    account: window.ethereum.selectedAddress
   // })
    //let res = await axios.post('https://minting.oxfordcircle.io/api/getWhitelistOxc', {
     let res = await axios.post('http://localhost:5000/api/getWhitelistOxc', {
        account: window.ethereum.selectedAddress,
        number: mintNumber
    })
   
    return res.data
  
  }
  
  const web3Instance = () => {
    if (window.ethereum) {
        return new Web3(window.ethereum);
    } else if (window.web3) {
        return new Web3(window.currentProvider);
    }
  };
    
  //clean code function
  export const mintCypherNews = async (url, name, description, mintNumber) => {
   
      const network = await getNetworkStatus()
        
      //production = 1
      if (network != 1){
        return {
          success: false,
          status: "Wrong Network. Please connect to Ethereum Mainnet to continue.",
        };  
      }
   
      const nMintNumber = 1;
        
      window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  
      const nftContractPF = window.contract;
      const blnPausedPF = await nftContractPF.methods._paused.call().call();
       
      if (blnPausedPF) {
          return {
              success: false,
              status: "Minting is not active.",
          };
      }   
   
      //const sPrice = await nftContractPF.methods.getPrice().call()
      //const amountToSend = 42000000000000000  // 10000000000000000;.01 eth
      const nonce = await web3.eth.getTransactionCount(window.ethereum.selectedAddress, 'latest'); //get latest nonce
      const ethAddress = window.ethereum.selectedAddress;
      const sMintPrice = (nMintNumber * contractMintPrice) //(nMintNumber * 0.042)
     
      try {
  
  
          var esGas;
          esGas = await web3.eth.estimateGas({
            from: ethAddress,
            to: contractAddress,     
            nonce: nonce,        
            value: web3.utils.toHex(web3.utils.toWei(String(sMintPrice), 'ether')),
            data: nftContractPF.methods.CypherMint().encodeABI()
          });
        
            //const sGasPrice = web3.utils.toHex(await web3.eth.getGasPrice());
            const sGasPrice = web3.utils.toHex(esGas);
  
            //the transaction
            const tx = {
                from: ethAddress,
                to: contractAddress,
                //gasPrice: sGasPrice,
                gas: sGasPrice,
                value: web3.utils.toHex(web3.utils.toWei(String(sMintPrice), 'ether')),
                data: nftContractPF.methods.CypherMint().encodeABI()
            };
  
     
  
            const txHash = await window.ethereum.request({
                method: "eth_sendTransaction",
                params: [tx],
            });
  
            return {
                success: true,
                status:
                "✅ Successful transaction. Check on Etherscan: https://etherscan.io/tx/" +
                txHash,
            };
  
        } catch (error) {
  
            return {
                success: false,
                status: "Something went wrong: " + error.message,
            };
  
        } 
  };
  