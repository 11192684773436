import React, { useState, useEffect } from 'react';
//import { View, Text, StyleSheet } from 'react-native';
import ConnectWallet from '../projects/ConnectWallet' 
import Minter from '../projects/Minter';
import { 
    getCurrentWalletConnected, 
    doesCypherExist,
  } from "../util/interact";

function RSS() {
  const [feed, setFeed] = useState([]);
  var parsedFeed = [];

  useEffect(async () => {

        await initPage();
 
  }, []);


  const initPage = async (e) => {
   
     const bln   = await isAccessGranted(); 
    
     if(bln){ 
            await showPage(); 

            await getbitcoinfeed();
            await getcointelegraphfeed(); 
            setTimeout(function(){
    
                setFeed(parsedFeed);
            }
            ,2000);
  
     }else{
        await hidePage(); 
         
     }
      

 }

    const isAccessGranted= async() => {
        const { address, status } = await getCurrentWalletConnected();
        //console.log(status);
        if(status == "connected"){
         
            const bal  = await doesCypherExist();

            if (bal>0){ 
                await hideMint();
                await showPage(); 
                return true; 
            }else{  
                await showMint();
                await hidePage(); 
                return false;
            }
        }else{ 
            await showMint();
            await hidePage(); 
            return false;
        }
        
    }

    const showPage = async() => {
        
        document.getElementById("MintSection").style.display = "none";
        document.getElementById("articles").style.display = "grid";

    }
    const hidePage = async() => {
     
        document.getElementById("MintSection").style.display = "block";
        document.getElementById("articles").style.display = "none";
    }

    const showMint = async() => {
     
        document.getElementById("MintSection").style.display = "block";
    }

    const hideMint = async() => {
     
        document.getElementById("MintSection").style.display = "none";
    }

    
    const getAltcoinFeeds = async() => {
        parsedFeed = [];
        //feeds

        const  bln   = await isAccessGranted(); 

        if(bln){ 
            await getcointelegraphfeedtag("altcoin");

            //reload articles
            setTimeout(function(){
                
                setFeed(parsedFeed);
            },2000);
        }
    }
  const getBitcoinFeeds = async() => {
    parsedFeed = [];
    //feeds

     const  bln  = await isAccessGranted(); 

    if(bln){ 

        await getbitcoinfeed();
        await getcointelegraphfeedtag("bitcoin");
    
        //reload articles
        setTimeout(function(){
            
            setFeed(parsedFeed);
        },2000);

    }

  }
  const getEthFeeds = async() => {
    parsedFeed = [];

    const  bln  = await isAccessGranted(); 

    if(bln){ 
 
        //feeds
        await getcointelegraphfeedtag("ethereum");

        //reload articles
        setTimeout(function(){
            
            setFeed(parsedFeed);
        },2000);

    }
    
  }
  const getBlockchainFeeds = async() => {
    parsedFeed = [];

    const  bln  = await isAccessGranted(); 

    if(bln){ 
 
        //feeds
        await getcointelegraphfeedtag("blockchain");

        //reload articles
        setTimeout(function(){
            
            setFeed(parsedFeed);
        },2000);

    }

  }
  const getRegulationFeeds = async() => {

    parsedFeed = [];

    const  bln  = await isAccessGranted(); 

    if(bln){

        //feeds
        await getcointelegraphfeedtag("regulation");

        //reload articles
        setTimeout(function(){
            
            setFeed(parsedFeed);
        },2000);
    }

  }
    const getWeb3Feeds = async() => {
       parsedFeed = [];

        const  bln  = await isAccessGranted(); 
 
        if(bln){

            //web3 feeds
            await getcointelegraphfeedtag("web3");

            //reload articles
            setTimeout(function(){
                    
                    setFeed(parsedFeed);
                },2000);
        }
    }
    const getbitcoinfeed = async() => {

       
            fetch('https://roswell.hiperweb.com/hwdotnet/hwgis/gisproxy/proxy.ashx?https://news.bitcoin.com/feed/')
                .then((response) => response.text())
                .then((responseData) => {
                // parse the RSS feed
                //console.log(responseData);
                const parser = new DOMParser();
                const xml = parser.parseFromString(responseData, 'application/xml');
                const items = xml.querySelectorAll('item');
                
                items.forEach((item) => {
                    parsedFeed.push({
                    title: item.querySelector('title').textContent,
                    link: item.querySelector('link').textContent,
                    description: item.querySelector('description').textContent.replace('<![CDATA[', '').replace(']]>', ''),
                    });
                });

            
            });

        
    }

    
    const getcointelegraphfeed = async() => {

        fetch('https://roswell.hiperweb.com/hwdotnet/hwgis/gisproxy/proxy.ashx?https://cointelegraph.com/rss/')
            .then((response) => response.text())
            .then((responseData) => {
            // parse the RSS feed
            //console.log(responseData);
            const parser = new DOMParser();
            const xml = parser.parseFromString(responseData, 'application/xml');
            
            const rss = xml.querySelector('rss');
    
            const channel = rss.querySelector('channel');
            const items = channel.querySelectorAll('item');
           
            items.forEach((item) => {
 
                parsedFeed.push({
                    title: item.querySelector('title').textContent,
                    link: item.querySelector('link').textContent,
                    description: item.querySelector('description').textContent.replace('<![CDATA[', '').replace(']]>', ''),
                });
            });

        
        });

    }
    const getcointelegraphfeedtag = async(tag) => {

      fetch('https://roswell.hiperweb.com/hwdotnet/hwgis/gisproxy/proxy.ashx?https://cointelegraph.com/rss/tag/' + tag)
          .then((response) => response.text())
          .then((responseData) => {
          // parse the RSS feed
          //console.log(responseData);
          const parser = new DOMParser();
          const xml = parser.parseFromString(responseData, 'application/xml');
          
          const rss = xml.querySelector('rss');
  
          const channel = rss.querySelector('channel');
          const items = channel.querySelectorAll('item');
         
          items.forEach((item) => {

              parsedFeed.push({
                  title: item.querySelector('title').textContent,
                  link: item.querySelector('link').textContent,
                  description: item.querySelector('description').textContent.replace('<![CDATA[', '').replace(']]>', ''),
              });
          });

      
      });

  }

  const disappointedSatoshi= ()=>{
    alert("you've disapponted satoshi. now you must live with the consequences.")
    document.getElementById("satoshi").style.display = "block";
  }
    

  return (
    <div id="content">
      <div id='satoshi'><img src='https://media.tenor.com/1h2X4VyLTxEAAAAM/satoshi-bitcoin.gif'></img></div>
      <nav>
        <div id='logo'><input type='text' placeholder='private key here!'></input>  <input type='button' onClick={disappointedSatoshi} value="GO" /> </div>
        <h1 className='welcome'>
          <img width={20} src='https://cdn2.lamag.com/wp-content/uploads/sites/6/2016/06/mchammer.gif'></img> Welcome to <span className='welcomeBlink'>Cyphernews!</span> <img width={20} src='https://cdn2.lamag.com/wp-content/uploads/sites/6/2016/06/mchammer.gif'></img>
        </h1>
        <p><img width={100} src="https://media.tenor.com/_5YVX67AISYAAAAj/godzilla-godz.gif"></img></p>
        <ul> 
          <li><a onClick={getBitcoinFeeds}>Bitcoin</a></li>
          <li><a onClick={getEthFeeds}>Ethereum</a></li> 
          <li><a onClick={getAltcoinFeeds}>Altcoin</a></li>
          <li><a onClick={getBlockchainFeeds}>Blockchain</a></li>
          <li><a onClick={getRegulationFeeds}>Regulation</a></li>
          <li><a onClick={getWeb3Feeds}>web3</a></li>
        </ul>
        <ConnectWallet></ConnectWallet> 
                                    
      </nav>
   
      <Minter></Minter> 
      <div id="articles">

        {feed.map((item) => (
          <div className='article'>
          <a target="_blank" href={item.link}>
            <img width={45} src='https://media.tenor.com/WC8DH-ARmUsAAAAj/godzilla-vs-kong.gif'></img><h2>{item.title}</h2>
          {/*item.description.replaceAll('"', '')*/} 
          </a>
        </div>
        ))}
      </div>
      <div id="ads">
         <p> <a href='https://restructuring.ra.kroll.com/FTX/' target="_blank">AD: Put your shitcoin here</a>
         <iframe width="260" height="215"
src="https://www.youtube.com/embed/HEXWRTEbj1I?autoplay=1&origin=http://localhost:3000" frameborder="0" 
allowfullscreen></iframe></p>
      </div> 
    </div>
  );
}
/*
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
  },
  item: {
    marginBottom: 16,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  description: {
    marginTop: 8,
    fontSize: 14,
  },
});*/

export default RSS;
