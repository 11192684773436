/* eslint-disable */
import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  doesCypherExist,
  mintCypherNews,
} from "../util/interact.js";

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'



const Minter = (props) => {
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [url, setURL] = useState("");

    useEffect(async () => {
        const { address, status } = await getCurrentWalletConnected();

        setWallet(address);

        if (status == "connected") {
            setStatus(<p>
                {" "}
                 {" "}

                <button id="mintButton" onClick={onMintPressed}>
                    Mint
                         </button>
            </p>
            );
        } else {
            setStatus(status);

        }

        addWalletListener();
    }, []);

    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    setWallet(accounts[0]);
                    // setStatus("Write a message in the text-field above.");
                    setStatus(
                        <p>
                            {" "}
                            {" "}
                            <button id="mintButton" onClick={onMintPressed}>
                                Mint
                       </button>
                        </p>
                    );
                } else {
                    setWallet("");
                    setStatus("Connect to your ethereum wallet using the top right button.");
                }
            });
        } else {
            setStatus(
                <p>
                    {" "}
                     {" "}
                    <a target="_blank" href={`dapp://cyphernews.io`}>
              You must install an ethereum wallet in your
              browser.
          </a>
                </p>
            );
        }
    }
    const showPage = async() => {
        
        document.getElementById("MintSection").style.display = "none";
        document.getElementById("articles").style.display = "grid";

    }
    const connectWalletPressed = async () => {

        const walletResponse = await connectWallet();

        if (walletResponse.status == "connected") {
            setStatus(<p>
                {" "}
                🦊{" "}
                <button id="mintButton" onClick={onMintPressed}>
                    Mint
            </button>
            </p>
            );
        } else {
            setStatus(walletResponse.status);

        }

        // setStatus(walletResponse.status);
        setWallet(walletResponse.address);
    };

    const onMintPressed = async () => {
       // console.log("pre Mint");
        const { success, status } = await mintCypherNews(url, name, description);
        // setStatus(status);
        //console.log(success);
        //console.log("call swal");

        if (!success) {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: status
            })

        } else {

            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: status
            })

            showPage();

        }
    setStatus(<p>
        {" "} 
        <section id="slideMint" > 
            <div className="row rowMint"> 
                <div className="Minter">
                    <button id="mintButton" onClick={onMintPressed}>
                    Mint
                    </button>
                </div>
            </div> 
        </section>
    </p>
    );
    //if (success) {
    //  setName("");
    //  setDescription("");
    //  setURL("");
   // }
  };
    
    return (
        <div data-v-app="" id="app">
            <div id="MintSection"> 
			    <section id="slide" > 
                    <div className="row rowMint">
                        <p id="status" style={{ width: '100%', color: "red" }}>
                            {status}
                        </p>
                    </div>
			    </section>  
	        </div>	 
        </div>
    );
};

export default Minter;
